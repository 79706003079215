<template>
  <div class="content">
    <iframe
      src="http://testweb11.iecworld.com/sxlt/demo/07/"
      style="height: 100%;width:100%; frameborder=no"
      frameborder="no"
      border="0"
      marginwidth="0"
      marginheight="0"
    ></iframe>
  </div>
</template>

<script>
import { Notify, List } from 'vant';
export default {
  name: 'VrWholeView',
  components: {
    [List.name]: List,
  },
  data() {
    return {};
  },
  watch: {},
  created() {
    // 跳到全景地图
    // window.location.href = 'http://testweb11.iecworld.com/sxlt/demo/07/';
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {},
};
</script>
<style scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>



